/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Tab from 'vux/src/components/tab/tab';
import TabItem from 'vux/src/components/tab/tab-item';
import { Dialog, Toast } from 'vant';
export default {
  name: 'OrderRecord',
  components: {
    Tab: Tab,
    TabItem: TabItem
  },
  data: function data() {
    return {
      active: 2,
      //点击哪个
      selectDat: '',
      //选择的年月
      month: '',
      //当前几月
      year: '',
      //当前年份
      startDate: '',
      endDate: '',
      goods: [],
      curPage: 1,
      //当前页
      totalPage: 2,
      //总页数
      pageNum: 4,
      //每页条数
      totalRecord: 0,
      // 一共几页
      pullUpLoad: {
        threshold: 25
      },
      emptyShow: false,
      initFlag: true,
      // =======
      isIcon: true,
      //是否是送餐的  true是  false 否（餐厅就餐）
      // isFooter:1,//两个情况 外送 1 结束的 2  其他情况为已经取消
      isPay: true,
      //是否已经支付
      isPopupShow: false,
      //配送是否展开
      // zIndex:'',
      zTeb: '',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      //是否开启下拉加载
      finishedtxt: '',
      //结束的话
      // ==================
      tebVal: ['1', '6'],
      // ,
      cotderName: '',
      //配送餐厅
      addName: '',
      //配送名字
      addTime: '',
      //配送时间
      showEmpty: false,
      cancellations: false,
      //撤单按钮
      takeDis: false,
      //取餐按钮
      // idNO:false,//是否空列表
      isShare: false,
      //是否为共享订单
      isNo: false,
      cancleShow: true // 撤单按钮是都显示

    };
  },
  watch: {
    isNo: function isNo(val) {
      if (val == true) {
        this.finishedtxt = '';
      } else {
        this.finishedtxt = '没有更多了';
      }
    }
  },
  methods: {
    //领取分享订单
    shareOrder: function shareOrder(type, mealId, orderId) {
      var _this = this;

      Dialog.confirm({
        title: '提示',
        confirmButtonColor: '#3399ff',
        message: '确认共享订单？'
      }).then(function () {
        _this.$utils.Tools.loadShow();

        _this.$api.Meal.shareOrder({
          mealId: mealId,
          //餐别编号
          orderId: orderId,
          //订单号
          goShare: type //1:共享  0：取消共享

        }).then(function (response) {
          _this.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);

            _this.finished = true;
          } else {
            if (type == 1) {
              Toast('订单共享成功');
            } else if (type == 0) {
              Toast('订单共享已取消');
            }

            _this.goods = [];

            _this.onRefresh();
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      }).catch(function () {});
    },
    getOrderColor: function getOrderColor(state) {
      if (state === '1') {
        return '#FF6600';
      } else if (state === '2') {
        return '#666666';
      } else {
        return '#3DD1B2';
      }
    },

    /** @function 定制-  只能订明天的  所以要判断一下
     *     lwt
     *   2020-07-07
     **/
    inspectDate: function inspectDate(date, time) {
      // date 是就餐时间  time 是截至时间
      //明天的时间
      // date ='2020-07-08'
      // time='17:39'
      //在就餐时间之前-返回true  就餐时间之后  返回false
      time = time + ':00';
      date = date.replace(/-/g, '/');
      var orderDate = date + ' ' + time;
      orderDate = new Date(orderDate).getTime();
      var now = new Date().getTime();
      return orderDate - now > 0;
    },

    /**
     * @function获取当前日期
     *      wzx
     *   2019.04.12
     **/
    getSysDate: function getSysDate() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);

          _this2.finished = true;
        } else {
          _this2.today = data.sysDate.substring(0, 10);
          _this2.timestamp = _this2.$utils.DateUtils.getTimeStamp(data.sysDate) * 1000;
          _this2.year = data.sysDate.substring(0, 4);
          _this2.month = data.sysDate.substring(5, 7);
          _this2.startDate = _this2.$utils.DateUtils.getMonthStart(data.sysDate.substring(0, 7));
          _this2.endDate = _this2.$utils.DateUtils.getMonthEnd(data.sysDate.substring(0, 7));
          _this2.goods = [];

          _this2.onRefresh();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },

    /**
     * @function点击年或月份，显示月份下拉菜单
     *      wzx
     *   2019.01.14
     **/
    changeDate: function changeDate(val) {
      this.year = val.substring(0, 4);
      this.month = val.substring(5, 7);
      this.curPage = 1;
      this.selectDat = this.year + '-' + this.month;
      this.startDate = this.$utils.DateUtils.getMonthStart(val);
      this.endDate = this.$utils.DateUtils.getMonthEnd(val);
      this.goods = []; // this.tebVal=['2','3','5'];

      this.onRefresh();
    },
    getOrderRecords: function getOrderRecords() {
      var _this3 = this;

      this.$api.Meal.getOrderRecords({
        pageIndex: this.curPage,
        pageSize: this.pageNum,
        search: {
          mealState: this.tebVal,
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);

          _this3.finished = true;
        } else {
          _this3.totalPage = data.totalPage;
          _this3.totalRecord = data.totalRecord;
          _this3.finished = false;

          if (data.records && data.records.length > 0) {
            if (_this3.curPage == 1) {
              _this3.goods = data.records;

              if (_this3.totalPage == 1) {
                _this3.finished = true;
              }
            } else {
              _this3.goods = [].concat(_toConsumableArray(_this3.goods), _toConsumableArray(data.records));
            }

            if (_this3.totalPage != 1) {
              _this3.curPage++;
            } else {
              _this3.curPage = 1;
              _this3.finished = true;
            }

            _this3.isNo = false;
          } else {
            _this3.goods = [];
            _this3.loading = false;
            _this3.finished = true;
            _this3.showEmpty = true;
            _this3.isNo = true;
          }

          _this3.updateScroll();
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    showClick: function showClick(orderId, mealId, overdue) {
      this.$router.push({
        path: '/OrderPayResult',
        query: {
          orderId: orderId,
          mealId: mealId,
          overdue: overdue
        }
      });
    },
    codeClick: function codeClick(orderId, mealId) {
      this.$router.push({
        path: '/foodCode',
        query: {
          orderId: orderId,
          mealId: mealId
        }
      });
    },
    // 撤销订单
    cancelOrder: function cancelOrder(subOrderId) {
      var _this4 = this;

      Dialog.confirm({
        title: '提示',
        message: '确定撤销订单吗？',
        confirmButtonColor: '#3399ff'
      }).then(function () {
        _this4.cancellations = true;

        _this4.$api.Meal.updateByPrimaryKey({
          subOrderId: subOrderId,
          mealState: '2'
        }).then(function (response) {
          _this4.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;
          _this4.cancellations = false;

          if (+code !== _this4.$code.success) {
            _this4.$utils.Tools.toastShow(msg);
          } else {
            _this4.$utils.Tools.toastShow(msg);

            _this4.goods = [];

            _this4.onRefresh();
          }
        }).catch(function () {
          _this4.$utils.Tools.httpError();

          _this4.$router.go(0);
        });
      }).catch(function () {});
    },
    // =============
    dispatching: function dispatching(cname, aname, stime, etime) {
      //配送详情
      this.isPopupShow = !this.isPopupShow;
      this.cotderName = cname;
      this.addName = aname;
      this.addTime = stime + '-' + etime;
    },
    makeSure: function makeSure() {
      this.isPopupShow = !this.isPopupShow;
    },
    onLoad: function onLoad() {
      var _this5 = this;

      setTimeout(function () {
        if (_this5.refreshing) {
          _this5.goods = [];
          _this5.refreshing = false;
        }

        _this5.curPage <= _this5.totalPage && _this5.getOrderRecords();

        if (_this5.goods.length >= _this5.totalRecord) {
          _this5.finished = true;
        }

        _this5.loading = false;
      }, 500);
    },
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.curPage = 0; // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.onLoad();
    },
    onItemClick: function onItemClick(val) {
      this.isNo = false;
      var a = [];
      a.push(val);

      if (val == '3') {
        val == '3' && a.push('2', '5', '7');
      } else if (val == '1') {
        val == '1' && a.push('6');
      }

      this.tebVal = a;
      this.$utils.Tools.loadHide();
      this.goods = [];
      this.onRefresh();
    },
    getOrder: function getOrder(id) {
      var _this6 = this;

      Dialog.confirm({
        title: '提示',
        message: '确定手动取餐吗？',
        confirmButtonColor: '#3399ff'
      }).then(function () {
        _this6.takeDis = true;

        _this6.$api.Meal.updateByPrimaryKey({
          subOrderId: id,
          mealState: '3'
        }).then(function (response) {
          _this6.takeDis = false;

          _this6.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this6.$code.success) {
            _this6.$utils.Tools.toastShow(msg);
          } else {
            _this6.$utils.Tools.toastShow(msg);

            _this6.goods = []; //  this.tebVal=['2','3'];

            _this6.onRefresh();
          }
        }).catch(function () {
          _this6.$utils.Tools.httpError();
        });
      }).catch(function () {// on cancel
        // Toast('领取失败，请稍后重试')
      });
    },
    // ==========item.mealId, item.orderId
    appraiseMsg: function appraiseMsg(id, mealId, orderId, name, fname, num) {
      var msgObj = {
        mealId: mealId,
        orderId: orderId,
        name: name,
        fname: fname,
        num: num
      };
      this.$utils.Store.setLocalItem('msgobj', JSON.stringify(msgObj));
      this.$router.push({
        path: '/OtderAppraise',
        query: {
          id: id
        }
      });
    }
  },
  created: function created() {
    this.getSysDate();
    this.zTeb = this.$utils.Store.getLocalItem('payType');

    if (this.zTeb == 3) {
      // this.tebVal[0] = 4
      this.onItemClick('4');
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  updated: function updated() {}
};